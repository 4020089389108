<!-- src/components/SubscriptionPlan.vue -->
<template>
    <div class="subscription-plan">
      <h2>Escolha seu Plano de Assinatura</h2>
      <ul class="planos">
        <li v-for="plano in planos" :key="plano.nome" :class="{ ativo: plano.nome === planoAtual }">
          <h3>{{ plano.nome }}</h3>
          <p>{{ plano.descricao }}</p>
          <p><strong>R$ {{ plano.preco.toFixed(2) }}</strong> / {{ plano.periodo }}</p>
          <button @click="selecionarPlano(plano)">Assinar {{ plano.nome }}</button>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: "SubscriptionPlan",
    data() {
      return {
        planoAtual: "Free",
        planos: [
          { nome: "Mensal", descricao: "Acesso por 1 mês", preco: 29.90, periodo: "mês" },
          { nome: "Semestral", descricao: "Acesso por 6 meses", preco: 149.90, periodo: "6 meses" },
          { nome: "Anual", descricao: "Acesso por 1 ano", preco: 279.90, periodo: "ano" },
          { nome: "Bianual", descricao: "Acesso por 2 anos", preco: 499.90, periodo: "2 anos" },
        ],
      };
    },
    methods: {
      selecionarPlano(plano) {
        // Implementar lógica de seleção de plano, como integração com PayPal
        alert(`Plano ${plano.nome} selecionado.`);
      },
    },
  };
  </script>
  
  <style scoped>
  .planos {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .planos li {
    border: 1px solid var(--color-primary);
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .planos li.ativo {
    background-color: var(--color-primary);
    color: var(--color-secondary);
  }
  
  button {
    background-color: var(--color-primary);
    color: var(--color-secondary);
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  button:hover {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
  </style>
  