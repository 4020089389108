<template>
  <div>
    <h2>Método de Pagamento</h2>
    <div id="paypal-button-container"></div>
  </div>
</template>

<script>
/* global paypal */
export default {
  name: "PaymentMethod",
  mounted() {
    // Aguarda o carregamento do SDK do PayPal
    if (typeof paypal !== "undefined") {
      paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: "9.99" // Exemplo de valor
              }
            }]
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then(details => {
            alert("Transação concluída por " + details.payer.name.given_name);
          });
        }
      }).render('#paypal-button-container');
    } else {
      console.error("SDK do PayPal não carregado.");
    }
  }
};
</script>

<style scoped>
.payment-method {
  text-align: center;
}

#paypal-button-container {
  margin-top: 15px;
}
</style>
