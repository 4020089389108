// src/firebaseConfig.js

// Importa as funções necessárias do Firebase SDK
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics"; // Para o serviço de Analytics (opcional)

// Configuração do Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAy65YjlfnoAoYwgDW0rsMLAFsHe4VDpik",
  authDomain: "mezzomo-music.firebaseapp.com",
  projectId: "mezzomo-music",
  storageBucket: "mezzomo-music.appspot.com",
  messagingSenderId: "402544243957",
  appId: "1:402544243957:web:51f6c05352d921aee941bc",
  measurementId: "G-J1E5D53PNX"
};

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);

// Exporta as instâncias do Firestore, Auth, Storage, e Analytics (caso precise do Analytics)
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const analytics = getAnalytics(app); // Opcional: inclua somente se precisar de Analytics

export { db, auth, storage, analytics };
