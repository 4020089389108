<!-- src/components/CouponInput.vue -->
<template>
    <div class="coupon-input">
      <h2>Aplicar Cupom de Desconto</h2>
      <input v-model="cupom" type="text" placeholder="Digite seu cupom" />
      <button @click="aplicarCupom">Aplicar</button>
      <p v-if="mensagem">{{ mensagem }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "CouponInput",
    data() {
      return {
        cupom: "",
        mensagem: "",
      };
    },
    methods: {
      aplicarCupom() {
        // Lógica para validar e aplicar o cupom
        if (this.cupom === "DESCONTO10") {
          this.mensagem = "Cupom aplicado com sucesso! Desconto de 10%";
        } else {
          this.mensagem = "Cupom inválido.";
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .coupon-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  input {
    padding: 5px;
    border: 1px solid var(--color-primary);
    border-radius: 5px;
  }
  
  button {
    background-color: var(--color-primary);
    color: var(--color-secondary);
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  button:hover {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
  </style>
  