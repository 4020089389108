// main.js

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import "@/assets/css/variables.css";

// Importa o Firebase e suas instâncias configuradas
import { auth } from './firebaseConfig';

const app = createApp(App);

// Verifica a autenticação do usuário no Firebase antes de iniciar o app
auth.onAuthStateChanged((user) => {
  if (user) {
    console.log('Usuário autenticado:', user);
  } else {
    console.log('Nenhum usuário autenticado');
  }
  app.use(router).mount('#app');
});
