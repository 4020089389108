<!-- src/components/configurations/AccountSettings.vue -->
<template>
    <div class="account-settings">
      <h2>Conta/Perfil</h2>
      <div class="field">
        <label>Nome</label>
        <span>{{ user.name }}</span>
        <button @click="openEditProfileModal('name')">Editar</button>
      </div>
  
      <div class="field">
        <label>E-mail</label>
        <span>{{ user.email }}</span>
        <button @click="openEditProfileModal('email')">Editar</button>
      </div>
  
      <div class="field">
        <label>Senha</label>
        <button @click="openEditProfileModal('password')">Alterar Senha</button>
      </div>
  
      <div class="field">
        <label>País</label>
        <select v-model="user.country">
          <option v-for="country in countries" :key="country" :value="country">{{ country }}</option>
        </select>
      </div>
  
      <div class="field">
        <button class="delete-account" @click="confirmDeleteAccount">Deletar Conta</button>
      </div>
  
      <!-- Modal de Edição de Perfil -->
      <EditProfileModal v-if="showEditModal" @close="showEditModal = false" :editField="editField" />
    </div>
  </template>
  
  <script>
  import EditProfileModal from "./EditProfileModal.vue";
  
  export default {
    name: "AccountSettings",
    components: {
      EditProfileModal
    },
    data() {
      return {
        user: {
          name: "Usuário Teste",
          email: "usuario@exemplo.com",
          country: "Brasil"
        },
        countries: ["Brasil", "Estados Unidos", "Alemanha", "Japão"],
        showEditModal: false,
        editField: null
      };
    },
    methods: {
      openEditProfileModal(field) {
        this.editField = field;
        this.showEditModal = true;
      },
      confirmDeleteAccount() {
        if (confirm("Tem certeza de que deseja excluir sua conta permanentemente?")) {
          // Lógica para excluir a conta
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* estilos do componente */
  .field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .delete-account {
    color: var(--color-primary);
    cursor: pointer;
  }
  </style>
  