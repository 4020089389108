<!-- src/components/configurations/PrivacySettings.vue -->
<template>
    <div class="privacy-settings">
      <h2>Filtros e Privacidade</h2>
      <div class="option">
        <label>Filtro de Conteúdo Sensível</label>
        <input type="checkbox" v-model="settings.sensitiveContent" />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PrivacySettings",
    data() {
      return {
        settings: {
          sensitiveContent: false
        }
      };
    }
  };
  </script>
  
  <style scoped>
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  </style>
  