<!-- src/components/configurations/NotificationAndNetworkSettings.vue -->
<template>
    <div class="notification-network-settings">
      <h2>Notificações e Preferências de Rede</h2>
      <div class="option">
        <label>Notificações de novos lançamentos</label>
        <input type="checkbox" v-model="settings.newReleases" />
      </div>
      <div class="option">
        <label>Trilhas recomendadas</label>
        <input type="checkbox" v-model="settings.recommendedTracks" />
      </div>
      <div class="option">
        <label>Lembretes para revisar aulas</label>
        <input type="checkbox" v-model="settings.reviewReminders" />
      </div>
      <div class="option">
        <label>Download apenas via Wi-Fi</label>
        <input type="checkbox" v-model="settings.wifiOnly" />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "NotificationAndNetworkSettings",
    data() {
      return {
        settings: {
          newReleases: true,
          recommendedTracks: false,
          reviewReminders: true,
          wifiOnly: true
        }
      };
    }
  };
  </script>
  
  <style scoped>
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  </style>
  