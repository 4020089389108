<template>
    <div class="tela-recuperacao-senha">
      <!-- Background -->
      <MainBackground />
  
      <!-- Conteúdo Principal -->
      <div class="main-content">
        <h1 class="recuperacao-titulo">Recuperação de Senha</h1>
        <p>Digite seu e-mail para receber um link de redefinição de senha.</p>
  
        <!-- Formulário para recuperação de senha -->
        <form @submit.prevent="enviarLinkRecuperacao" class="recuperacao-form">
          <div class="form-group" :class="{ 'has-error': emailErro }">
            <label for="email">E-mail</label>
            <input type="email" id="email" v-model="email" placeholder="Digite seu e-mail" required />
            <span v-if="emailErro" class="error-message">{{ emailErro }}</span>
          </div>
  
          <!-- Botão para enviar link de recuperação -->
          <button type="submit" class="btn-recuperacao" :disabled="enviando">
            <span v-if="enviando">Enviando...</span>
            <span v-else>Enviar Link de Recuperação</span>
          </button>
  
          <!-- Mensagem de Sucesso ou Erro -->
          <p v-if="mensagem" :class="{'success-message': sucesso, 'error-message': !sucesso}">{{ mensagem }}</p>
        </form>
  
        <!-- Botão para voltar ao login -->
        <p class="voltar-login">
          <a @click.prevent="voltarParaLogin" href="#">Voltar para o login</a>
        </p>
      </div>
    </div>
  </template>
  
  <script>
  import { getAuth, sendPasswordResetEmail } from "firebase/auth";
  import MainBackground from "@/components/MainBackground.vue";
  
  export default {
    name: "TelaRecuperacaoSenha",
    components: {
      MainBackground,
    },
    data() {
      return {
        email: "",
        emailErro: null,
        enviando: false,
        mensagem: null,
        sucesso: false,
      };
    },
    methods: {
      validarEmail() {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(this.email)) {
          this.emailErro = "Por favor, insira um e-mail válido.";
          return false;
        }
        this.emailErro = null;
        return true;
      },
      async enviarLinkRecuperacao() {
        if (this.validarEmail()) {
          this.enviando = true;
          const auth = getAuth();
  
          try {
            await sendPasswordResetEmail(auth, this.email);
            this.mensagem = "Email de recuperação de senha enviado com sucesso! Verifique sua caixa de entrada.";
            this.sucesso = true;
          } catch (error) {
            console.error("Erro ao enviar email de recuperação:", error);
            this.mensagem = "Erro ao enviar email de recuperação. Tente novamente mais tarde.";
            this.sucesso = false;
          } finally {
            this.enviando = false;
          }
        }
      },
      voltarParaLogin() {
        this.$router.push({ name: "TelaLogin" });
      },
    },
  };
  </script>
  
  <style scoped>
  @import "@/assets/css/variables.css";
  
  .tela-recuperacao-senha {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: var(--color-secondary);
  }
  
  .main-content {
    position: relative;
    z-index: 2;
    background-color: rgba(var(--color-dark-rgb), 0.7);
    padding: var(--padding-default);
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .recuperacao-titulo {
    font-size: var(--size-big-2);
    color: var(--color-primary);
    margin-bottom: 20px;
  }
  
  p {
    font-size: var(--size-large-1);
    margin: 20px 0;
    color: var(--color-secondary);
  }
  
  .recuperacao-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    font-size: var(--size-large-1);
    margin-bottom: 5px;
    display: block;
    text-align: left;
    color: var(--color-secondary);
  }
  
  input {
    width: 100%;
    padding: 10px;
    font-size: var(--size-large-1);
    border: 2px solid var(--color-secondary);
    border-radius: 5px;
    background-color: rgba(var(--color-secondary-rgb), 0.1);
    color: var(--color-secondary);
  }
  
  input:focus {
    outline: none;
    border-color: var(--color-primary);
  }
  
  .btn-recuperacao {
    font-size: var(--size-large-1);
    padding: 10px;
    background-color: var(--color-primary);
    color: var(--color-secondary);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .btn-recuperacao:disabled {
    background-color: rgba(var(--color-primary-rgb), 0.6);
    cursor: not-allowed;
  }
  
  .btn-recuperacao:hover:enabled {
    background-color: rgba(var(--color-primary-rgb), 0.8);
  }
  
  .voltar-login {
    margin-top: 20px;
  }
  
  a {
    color: var(--color-highlight);
    cursor: pointer;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .success-message {
    color: var(--color-highlight);
  }
  
  .error-message {
    color: var(--color-primary);
    font-size: var(--size-small-3);
    text-align: left;
    margin-top: 5px;
  }
  
  /* Responsividade para Mobile */
  @media (max-width: 480px) {
    .main-content {
      padding: var(--padding-default) 20px;
    }
  
    .recuperacao-titulo {
      font-size: var(--size-large-3);
    }
  
    .btn-recuperacao {
      font-size: var(--size-large-2);
    }
  }
  </style>
  