<!-- src/components/configurations/SessionSettings.vue -->
<template>
    <div class="session-settings">
      <h2>Sessão e Logoff</h2>
      <button @click="logoff">Logoff</button>
    </div>
  </template>
  
  <script>
  export default {
    name: "SessionSettings",
    methods: {
      logoff() {
        // Lógica para fazer logoff
        this.$router.push({ name: "TelaLogin" });
      }
    }
  };
  </script>
  
  <style scoped>
  button {
    color: var(--color-primary);
    cursor: pointer;
  }
  </style>
  