<!-- TELAINICIO.VUE -->
<template>
  <div class="inicio">
    <!-- Cabeçalho -->
    <header class="header">
      <div class="header-container">
        <div class="icon perfil" @click="abrirConfiguracoes">
          <i class="fa-solid fa-circle-user"></i>
        </div>
        <h1 class="title">
          <span class="mezzomo">mezzomo </span>
          <span class="music">music</span>
        </h1>
        <div class="icon busca" @click="tooltip('Busca em breve.')">
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
      </div>
    </header>

    <!-- Conteúdo Principal -->
    <div class="main-content">
      <!-- Carrossel de Meus Cursos -->
      <MainCarrossel v-if="cursos.length > 0" :items="cursos" titulo="Meus Cursos" @itemSelecionado="abrirCurso" />

      <!-- Carrossel de Conheça os Cursos (se não houver cursos dinâmicos) -->
      <MainCarrossel v-else :items="cursosSugeridos" titulo="Conheça os Cursos" @itemSelecionado="tooltip" />

      <!-- Carrossel de Minhas Trilhas -->
      <MainCarrossel v-if="trilhas.length > 0" :items="trilhas" titulo="Minhas Trilhas"
        @itemSelecionado="abrirTrilha" />

      <!-- Carrossel de Conheça as Trilhas -->
      <MainCarrossel v-else :items="trilhasSugeridas" titulo="Conheça as Trilhas" @itemSelecionado="tooltip" />

      <!-- Exibir erro se houver -->
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import MainCarrossel from '@/components/MainCarrossel.vue';
import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage';

export default {
  components: {
    MainCarrossel,
  },
  data() {
    return {
      cursos: [],
      trilhas: [],
      cursosSugeridos: [],
      trilhasSugeridas: [],
      error: null,
      scrollTimeout: null,
      isMobile: window.innerWidth <= 768, // Define o tamanho da tela
    };
  },
  created() {
    this.loadCursosTrilhas();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.updateIsMobile);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateIsMobile);
  },
  methods: {
    async loadCursosTrilhas() {
      try {
        const cachedCursos = localStorage.getItem('cursos');
        const cachedTrilhas = localStorage.getItem('trilhas');

        // Verifica se há cache no localStorage
        if (cachedCursos && cachedTrilhas) {
          console.log('Carregando cursos e trilhas do cache localStorage...');
          this.cursos = JSON.parse(cachedCursos);
          this.trilhas = JSON.parse(cachedTrilhas);
          return;
        }

        console.log('Carregando dados do Firebase Storage...');
        const storage = getStorage();

        // Carregar cursos
        const cursosRef = ref(storage, 'cursos/');
        const cursosList = await listAll(cursosRef);
        this.cursos = await Promise.all(
          cursosList.prefixes.map(async (cursoFolder) => {
            const cursoId = cursoFolder.name;
            const cursoJsonRef = ref(storage, `cursos/${cursoId}/curso.json`);
            const capaRef = ref(storage, `cursos/${cursoId}/${this.isMobile ? 'cover-130.webp' : 'cover.webp'}`);

            try {
              const cursoUrl = await getDownloadURL(cursoJsonRef);
              const capaUrl = await getDownloadURL(capaRef);

              const response = await fetch(cursoUrl);
              const cursoData = await response.json();

              return {
                ...cursoData,
                capa: capaUrl,
              };
            } catch (error) {
              console.error(`Erro ao carregar dados do curso ${cursoId}:`, error);
              return null;
            }
          })
        );

        this.cursos = this.cursos.filter((curso) => curso !== null);

        // Carregar trilhas
        const trilhasJsonRef = ref(storage, 'trilhas/trilhas.json');
        try {
          const trilhasUrl = await getDownloadURL(trilhasJsonRef);
          const response = await fetch(trilhasUrl);
          const trilhasData = await response.json();

          this.trilhas = await Promise.all(
            trilhasData.map(async (trilha) => {
              const capaRef = ref(storage, `trilhas/${trilha.id}/${this.isMobile ? 'cover-130.webp' : 'cover.webp'}`);
              try {
                trilha.capa = await getDownloadURL(capaRef);
              } catch (error) {
                console.error(`Erro ao carregar capa para trilha ${trilha.id}:`, error);
                trilha.capa = '';
              }
              return trilha;
            })
          );
        } catch (error) {
          console.error('Erro ao carregar o arquivo trilhas.json:', error);
          this.trilhas = [];
        }

        // Salvar no localStorage
        localStorage.setItem('cursos', JSON.stringify(this.cursos));
        localStorage.setItem('trilhas', JSON.stringify(this.trilhas));
      } catch (err) {
        console.error('Erro ao carregar cursos e trilhas:', err);
        this.error = 'Erro ao carregar cursos e trilhas.';
      }
    },
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    handleScroll() {
      const header = document.querySelector('.header');
      if (window.scrollY > 50) {
        header.classList.add('scrolled');
        if (this.scrollTimeout) {
          clearTimeout(this.scrollTimeout);
        }
        this.scrollTimeout = setTimeout(() => {
          header.classList.remove('scrolled');
        }, 1500);
      } else {
        header.classList.remove('scrolled');
      }
    },
    tooltip(mensagem) {
      alert(mensagem);
    },
    abrirCurso(curso) {
      this.$router.push({ name: 'TelaCurso', params: { id: curso.id } });
    },
    abrirTrilha(trilha) {
      this.$router.push({ path: `/trilha/${trilha.id}` });
    },
    abrirConfiguracoes() {
      this.$router.push({ name: 'TelaConfiguracoes' });
    },
  },
};
</script>




<style scoped>
@import "@/assets/css/variables.css";

.inicio {
  color: var(--color-primary);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--color-dark);
  transition: background-color 0.3s, opacity 0.3s;
}

.header-container {
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 calc(var(--padding-default) * 1.125);
  /* 45px */
  height: calc(var(--padding-default) * 2);
  /* 80px */
}

.main-content {
  padding-top: calc(var(--padding-default) * 2);
  /* 80px */
}

/* Mobile */
@media (max-width: 480px) {
  .header .title .music {
    padding-left: 5px;
  }
}
</style>