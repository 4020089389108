<!-- src/components/configurations/SubscriptionSettings.vue -->
<template>
    <div class="subscription-settings">
      <h2>Assinatura e Pagamento</h2>
      <p>Gerencie sua assinatura e detalhes de pagamento.</p>
      <button @click="irParaPagamentos">Ir para Pagamentos</button>
    </div>
  </template>
  
  <script>
  export default {
    name: "SubscriptionSettings",
    methods: {
      irParaPagamentos() {
        this.$router.push({ name: "TelaPagamento" });
      }
    }
  };
  </script>
  
  <style scoped>
  /* estilos do componente */
  button {
    color: var(--color-primary);
    cursor: pointer;
  }
  </style>
  