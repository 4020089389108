<!-- src/components/configurations/AppearanceAndLanguageSettings.vue -->
<template>
    <div class="appearance-language-settings">
      <h2>Aparência e Idioma</h2>
      <div class="option">
        <label>Idioma</label>
        <select v-model="settings.language">
          <option value="pt">Português</option>
          <option value="en">Inglês</option>
          <option value="de">Alemão</option>
        </select>
      </div>
      <div class="option">
        <label>Tema do App</label>
        <select v-model="settings.theme">
          <option value="light">Claro</option>
          <option value="dark">Escuro</option>
        </select>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "AppearanceAndLanguageSettings",
    data() {
      return {
        settings: {
          language: "pt",
          theme: "light"
        }
      };
    }
  };
  </script>
  
  <style scoped>
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  </style>
  