<!-- src/views/TelaConfiguracoes.vue -->
<template>
  <div class="tela-configuracoes">
    <!-- Background -->
    <MainBackground />

    <!-- Cabeçalho da Tela -->
    <header class="header">
      <button class="btn-voltar" @click="voltar">
        <i class="fa-solid fa-arrow-left"></i> Voltar
      </button>
      <h1>Configurações</h1>
    </header>

    <!-- Seções de Configuração -->
    <section class="configuracoes-secao">
      <AccountSettings />
      <SubscriptionSettings />
      <NotificationAndNetworkSettings />
      <AppearanceAndLanguageSettings />
      <PrivacySettings />
      <SessionSettings />
    </section>
  </div>
</template>

<script>
// Importa os componentes de configuração
import MainBackground from "@/components/MainBackground.vue";
import AccountSettings from "@/components/AccountSettings.vue";
import SubscriptionSettings from "@/components/SubscriptionSettings.vue";
import NotificationAndNetworkSettings from "@/components/NotificationAndNetworkSettings.vue";
import AppearanceAndLanguageSettings from "@/components/AppearanceAndLanguageSettings.vue";
import PrivacySettings from "@/components/PrivacySettings.vue";
import SessionSettings from "@/components/SessionSettings.vue";

export default {
  name: "TelaConfiguracoes",
  components: {
    MainBackground,
    AccountSettings,
    SubscriptionSettings,
    NotificationAndNetworkSettings,
    AppearanceAndLanguageSettings,
    PrivacySettings,
    SessionSettings
  },
  methods: {
    voltar() {
      this.$router.push({ name: "TelaInicio" });
    }
  }
};
</script>

<style scoped>
@import "@/assets/css/variables.css";

/* Estilos da Tela de Configurações */
.tela-configuracoes {
  position: relative;
  z-index: 1;
  color: var(--color-secondary);
}

.header {
  display: flex;
  align-items: center;
  padding: var(--padding-default);
  background-color: rgba(var(--color-dark-rgb), 0.8);
}

.btn-voltar {
  margin-right: auto;
  font-size: var(--size-large-1);
  color: var(--color-primary);
  background: none;
  border: none;
  cursor: pointer;
}

h1 {
  text-align: center;
  font-size: var(--size-big-1);
  color: var(--color-primary);
}

.configuracoes-secao {
  padding: var(--padding-default);
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
