<template>
  <div class="tela-curso">
    <!-- Cabeçalho fixo -->
    <header class="header">
      <div class="header-container">
        <button class="btn-voltar" @click="voltar">
          <i class="fa-solid fa-arrow-left"></i> Voltar
        </button>
        <h1 class="curso-nome">{{ curso.nome }}</h1>
      </div>
    </header>

    <!-- Conteúdo Principal -->
    <div class="main-content">
      <!-- Exibir detalhes do curso -->
      <div v-if="mostrarCurso">
        <!-- Detalhes do Curso -->
        <div class="curso-detalhes">
          <div class="curso-capa">
            <img :src="curso.capa" :alt="curso.nome" class="capa-img" />
          </div>
          <!-- Informações do Curso -->
          <div class="curso-info">
            <p class="curso-descricao">{{ curso.descricao }}</p>
            <p class="curso-estilo">Estilo: <span class="italico">{{ curso.estilo }}</span></p>
            <p class="curso-idioma">Idioma: <span class="italico">{{ curso.idioma }}</span></p>
            <p class="curso-duracao">Duração total: <span class="italico">{{ duracaoTotal }}</span></p>
            <p class="curso-data-inicio" v-if="curso.dataInicio">Iniciado em: <span class="italico">{{ curso.dataInicio }}</span></p>
            <!-- Botões de Ação -->
            <div class="curso-acoes">
              <div class="curso-acao" @click="baixarAudios">
                <i class="fa-solid fa-download"></i>
                <span>Download</span>
              </div>
              <div class="curso-acao" @click="compartilharCurso">
                <i class="fa-solid fa-share-nodes"></i>
                <span>Compartilhar</span>
              </div>
              <div class="curso-acao" @click="rolarParaComentarios">
                <i class="fa-solid fa-comments"></i>
                <span>Comentários</span>
              </div>
              <div class="curso-acao" @click="acessarCertificado" :disabled="!certificadoDisponivel">
                <i class="fa-solid fa-flag-checkered"></i>
                <span>Certificado</span>
              </div>
              <div class="curso-acao" @click="acessarQuiz">
                <i class="fa-solid fa-circle-question"></i>
                <span>Quiz</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Lista de Aulas -->
        <div class="curso-aulas">
          <h2>Aulas</h2>
          <ul>
            <li v-for="(aulaItem, index) in curso.aulas" :key="aulaItem.id" @click="tocarAula(aulaItem, index)">
              <div class="aula-detalhes">
                <i class="fa-solid fa-play"></i>
                <span>{{ aulaItem.nome }}</span>
              </div>
              <div class="aula-duracao">
                <span>{{ formatarDuracao(aulaItem.duracao) }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- Player de Áudio -->
      <div v-if="mostrarPlayer" class="tela-player">
        <!-- Cabeçalho do Player -->
        <div class="player-header">
          <button class="btn-voltar" @click="voltarParaCurso">
            <i class="fa-solid fa-arrow-left"></i> Voltar
          </button>
          <button class="btn-favoritar" @click="toggleFavorito">
            <i :class="['fa-solid fa-star', aula.favorito ? 'favorito-ativo' : '']"></i>
          </button>
        </div>

        <!-- Exibição da Capa ou Letra -->
        <div class="media-section">
          <img v-if="mostrarCapa" :src="curso.capa" :alt="curso.nome" class="capa-img" />

          <!-- Exibição das Letras -->
          <div v-else class="exibir-letra">
            <div class="letras-container">
              <div class="letras" :style="{ transform: `translateY(${scrollPosition}px)` }">
                <p v-for="(linha, index) in letras" :key="index" :class="{ ativa: index === linhaAtual }">
                  {{ linha.texto }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Rodapé: Controles de áudio e informações -->
        <div class="player-footer">
          <div class="info-line info-line-1">
            <span class="aula-nome">{{ aula.nome }}</span>
            <button class="btn-ver-letra" @click="toggleLetra">
              <i :class="mostrarCapa ? 'fa-solid fa-file-lines' : 'fa-solid fa-image'"></i>
              <span>{{ mostrarCapa ? ' Ver Letra' : ' Ver Capa' }}</span>
            </button>
          </div>
          <div class="info-line">
            <span class="curso-nome">{{ curso.nome }}</span>
            <button class="btn-repeat" @click="toggleRepeat">
              <i :class="[repeat ? 'fa-solid fa-repeat repeat-active' : 'fa-solid fa-repeat']"></i>
            </button>
          </div>

          <!-- Controles de Áudio -->
          <div class="audio-player">
            <audio
              ref="audioElement"
              :src="aula.arquivo"
              preload="auto"
              @loadedmetadata="onLoadedMetadata"
              @canplay="onCanPlay"
              @timeupdate="onTimeUpdate"
              @play="onPlay"
              @pause="onPause"
              @ended="onEnded"
              @error="onError"
            ></audio>

            <!-- Controles -->
            <div class="controls">
              <button @click="rewind" v-if="showRewind"><i class="fa-solid fa-backward-step"></i></button>
              <button @click="togglePlay">
                <i :class="isPlaying ? 'fa-solid fa-pause' : 'fa-solid fa-play'"></i>
              </button>
              <button @click="forward" v-if="showForward"><i class="fa-solid fa-forward-step"></i></button>
            </div>

            <!-- Barra de Progresso -->
            <div class="progress-container" v-if="showProgress">
              <input type="range" min="0" :max="duration" v-model="currentTime" @input="seek" />
            </div>

            <!-- Tempo -->
            <div class="time-container" v-if="showProgress">
              <span class="current-time">{{ formatTime(currentTime) }}</span>
              <span class="total-time">{{ formatTime(duration) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";

export default {
  data() {
    return {
      mostrarCurso: true,
      mostrarPlayer: false,
      mostrarCapa: true,
      curso: {
        id: this.$route.params.id,
        nome: "",
        estilo: "",
        descricao: "",
        capa: "",
        dataInicio: null,
        aulas: [],
      },
      duracaoTotal: "0h 0min",
      certificadoDisponivel: false,
      // Dados do Player
      aula: {
        id: '',
        nome: '',
        arquivo: null,
        arquivoLRC: '',
        favorito: false,
      },
      currentAulaIndex: 0,
      letras: [],
      linhaAtual: 0,
      repeat: false,
      scrollPosition: 0,
      lineHeight: 40,
      currentTime: 0,
      isPlaying: false,
      duration: 0,
      audioReady: false,
      showRewind: true,
      showForward: true,
      showProgress: true,
    };
  },
  async created() {
    await this.carregarCurso();
  },
  methods: {
    async carregarCurso() {
      try {
        // Verificar no localStorage
        const cachedCurso = localStorage.getItem(`curso_${this.curso.id}`);
        if (cachedCurso) {
          this.curso = JSON.parse(cachedCurso);
          this.calcularDuracaoTotal();
          this.verificarCertificadoDisponivel();
          return;
        }

        // Buscar dados do curso no Firebase Storage
        const storage = getStorage();
        const cursoJsonRef = storageRef(storage, `cursos/${this.curso.id}/curso.json`);
        const capaRef = storageRef(storage, `cursos/${this.curso.id}/cover.webp`);

        const cursoUrl = await getDownloadURL(cursoJsonRef);
        const capaUrl = await getDownloadURL(capaRef);

        const response = await fetch(cursoUrl);
        const cursoData = await response.json();

        this.curso = { ...cursoData, capa: capaUrl };

        // Carregar aulas do curso
        await this.carregarAulas();

        // Salvar no localStorage
        localStorage.setItem(`curso_${this.curso.id}`, JSON.stringify(this.curso));

        this.calcularDuracaoTotal();
        this.verificarCertificadoDisponivel();
      } catch (err) {
        console.error("Erro ao carregar o curso:", err);
      }
    },
    async carregarAulas() {
      try {
        const storage = getStorage();
        this.curso.aulas = await Promise.all(
          this.curso.aulas.map(async (aula) => {
            const audioRef = storageRef(storage, `cursos/${this.curso.id}/${aula.arquivo}`);
            try {
              const audioUrl = await getDownloadURL(audioRef);
              const duracao = await this.obterDuracaoAudio(audioUrl);
              return {
                ...aula,
                arquivo: audioUrl,
                duracao: duracao,
              };
            } catch (error) {
              console.error(`Erro ao carregar áudio para a aula ${aula.nome}:`, error);
              return {
                ...aula,
                arquivo: '',
                duracao: 0,
              };
            }
          })
        );
      } catch (error) {
        console.error("Erro ao carregar as aulas:", error);
      }
    },
    async obterDuracaoAudio(url) {
      return new Promise((resolve) => {
        const audio = new Audio(url);
        audio.addEventListener("loadedmetadata", () => {
          resolve(Math.floor(audio.duration));
        });
        audio.addEventListener("error", () => {
          resolve(0);
        });
      });
    },
    calcularDuracaoTotal() {
      let totalSegundos = this.curso.aulas.reduce((total, aula) => total + (aula.duracao || 0), 0);
      const horas = Math.floor(totalSegundos / 3600);
      const minutos = Math.floor((totalSegundos % 3600) / 60);
      this.duracaoTotal = `${horas}h ${minutos}min`;
    },
    verificarCertificadoDisponivel() {
      this.certificadoDisponivel = false; // Pode ser alterado conforme sua lógica
    },
    voltar() {
      this.$router.push({ name: "TelaInicio" });
    },
    voltarParaCurso() {
      this.mostrarPlayer = false;
      this.mostrarCurso = true;
      // Parar o áudio
      if (this.$refs.audioElement) {
        this.$refs.audioElement.pause();
      }
    },
    baixarAudios() {
      alert("Download iniciado para todos os áudios do curso.");
    },
    compartilharCurso() {
      alert("Compartilhar curso (funcionalidade em desenvolvimento).");
    },
    rolarParaComentarios() {
      alert("Rolando para a sessão de comentários.");
    },
    acessarCertificado() {
      if (this.certificadoDisponivel) {
        this.$router.push({ path: `/certificado/${this.curso.id}` });
      }
    },
    acessarQuiz() {
      this.$router.push({ path: `/quiz/${this.curso.id}` });
    },
    async tocarAula(aulaSelecionada, index) {
      if (aulaSelecionada.arquivo) {
        this.aula = { ...aulaSelecionada };
        this.currentAulaIndex = index;
        // Tenta carregar letras, se o arquivo LRC existir
        this.aula.arquivoLRC = this.aula.arquivo.replace('.mp3', '.lrc');
        if (await this.verificarArquivoLRC(this.aula.arquivoLRC)) {
          await this.carregarLetras();
        } else {
          this.letras = [];
        }

        // Mostrar o player e esconder o curso
        this.mostrarCurso = false;
        this.mostrarPlayer = true;

        // Configurar o áudio
        this.$nextTick(() => {
          if (this.$refs.audioElement) {
            this.$refs.audioElement.load();
            this.audioReady = false;
            this.isPlaying = false;
          }
        });
      } else {
        alert("O arquivo de áudio não está disponível.");
      }
    },
    async carregarLetras() {
      try {
        const response = await fetch(this.aula.arquivoLRC);
        if (!response.ok) {
          console.warn(`Arquivo LRC não encontrado para ${this.aula.nome}.`);
          this.letras = [];
          return;
        }
        const texto = await response.text();
        this.letras = this.parseLRC(texto);
      } catch (error) {
        console.error("Erro ao carregar o arquivo LRC:", error);
      }
    },
    parseLRC(lrcContent) {
      const linhas = lrcContent.split("\n");
      return linhas
        .map((linha) => {
          const match = linha.match(/\[(\d{2}):(\d{2}):(\d{2})\](.*)/);
          if (match) {
            const horas = parseInt(match[1], 10);
            const minutos = parseInt(match[2], 10);
            const segundos = parseInt(match[3], 10);
            const tempo = horas * 3600 + minutos * 60 + segundos;
            return { tempo, texto: match[4] };
          }
          return null;
        })
        .filter(Boolean);
    },
    async verificarArquivoLRC(url) {
      try {
        const response = await fetch(url);
        return response.ok; // Retorna true se o arquivo existir
      } catch {
        return false;
      }
    },
    formatarDuracao(segundos) {
      const minutos = Math.floor(segundos / 60);
      const segundosRestantes = segundos % 60;
      const segundosFormatados = segundosRestantes < 10 ? `0${segundosRestantes}` : segundosRestantes;
      return `${minutos}:${segundosFormatados}`;
    },
    toggleFavorito() {
      this.aula.favorito = !this.aula.favorito;
    },
    toggleLetra() {
      this.mostrarCapa = !this.mostrarCapa;
    },
    rewind() {
      if (this.currentAulaIndex > 0) {
        this.currentAulaIndex--;
        this.atualizarAulaAtual();
      }
    },
    forward() {
      if (this.currentAulaIndex < this.curso.aulas.length - 1) {
        this.currentAulaIndex++;
        this.atualizarAulaAtual();
      } else if (this.repeat) {
        // Repetir o curso desde o início
        this.currentAulaIndex = 0;
        this.atualizarAulaAtual();
      } else {
        // Final do curso
        console.log("Fim do curso");
      }
    },
    async atualizarAulaAtual() {
      this.aula = { ...this.curso.aulas[this.currentAulaIndex] };
      this.aula.arquivoLRC = this.aula.arquivo.replace('.mp3', '.lrc');

      // Tenta carregar letras, se o arquivo LRC existir
      if (await this.verificarArquivoLRC(this.aula.arquivoLRC)) {
        await this.carregarLetras();
      } else {
        this.letras = [];
      }

      // Configurar o áudio
      this.$nextTick(() => {
        if (this.$refs.audioElement) {
          this.$refs.audioElement.load();
          this.audioReady = false;
          this.isPlaying = false;
        }
      });
    },
    updateTime(currentTime) {
      this.currentTime = currentTime;
      this.updateLinhaAtual();
    },
    updateLinhaAtual() {
      const index = this.letras.findIndex((letra) => letra.tempo > this.currentTime);
      this.linhaAtual = index === -1 ? this.letras.length - 1 : index - 1;

      const lineHeight = this.lineHeight;
      const containerHeight = lineHeight * 7;
      const offset = (containerHeight / 2) - (lineHeight / 2);
      let scrollPosition = -(this.linhaAtual * lineHeight - offset);

      const totalHeight = this.letras.length * lineHeight;
      const maxScroll = 0;
      const minScroll = -(totalHeight - containerHeight);

      scrollPosition = Math.min(scrollPosition, maxScroll);
      scrollPosition = Math.max(scrollPosition, minScroll);

      this.scrollPosition = scrollPosition;
    },
    toggleRepeat() {
      this.repeat = !this.repeat;
    },
    onCanPlay() {
      this.audioReady = true;
      // Auto play
      this.playAudio();
    },
    onLoadedMetadata() {
      const audio = this.$refs.audioElement;
      if (audio) {
        this.duration = Math.floor(audio.duration);
      }
    },
    onTimeUpdate() {
      const audio = this.$refs.audioElement;
      if (audio) {
        this.currentTime = audio.currentTime;
        this.updateLinhaAtual();
      }
    },
    onPlay() {
      this.isPlaying = true;
    },
    onPause() {
      this.isPlaying = false;
    },
    togglePlay() {
      if (this.audioReady) {
        this.isPlaying ? this.pauseAudio() : this.playAudio();
      } else {
        console.warn('O áudio ainda não está pronto para ser reproduzido.');
      }
    },
    playAudio() {
      const audio = this.$refs.audioElement;
      if (audio && this.audioReady) {
        audio.play().catch((error) => {
          console.error('Erro ao tentar reproduzir o áudio:', error);
        });
      }
    },
    pauseAudio() {
      const audio = this.$refs.audioElement;
      if (audio) {
        audio.pause();
      }
    },
    seek(event) {
      const newTime = event.target.value;
      const audio = this.$refs.audioElement;
      if (audio) {
        audio.currentTime = newTime;
        this.currentTime = newTime;
      }
    },
    onEnded() {
      this.isPlaying = false;
      this.forward();
    },
    onError(event) {
      const error = event.target.error;
      switch (error.code) {
        case error.MEDIA_ERR_ABORTED:
          console.error('O usuário interrompeu o carregamento do áudio.');
          break;
        case error.MEDIA_ERR_NETWORK:
          console.error('Erro de rede durante o carregamento do áudio.');
          break;
        case error.MEDIA_ERR_DECODE:
          console.error('Erro de decodificação do áudio.');
          break;
        case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          console.error('O formato do áudio não é suportado ou o caminho do arquivo está incorreto.', this.aula.arquivo);
          break;
        default:
          console.error('Erro desconhecido ao carregar o áudio.');
          break;
      }
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secondsRemaining = Math.floor(seconds % 60);
      return `${minutes}:${secondsRemaining < 10 ? '0' + secondsRemaining : secondsRemaining}`;
    },
  },
};
</script>


<style scoped>
@import "@/assets/css/variables.css";

/* Estilos da Tela de Curso */
.tela-curso {
  color: var(--color-secondary);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--color-dark);
  height: calc(var(--padding-default) * 2);
  box-sizing: border-box;
  transition: background-color 0.3s, opacity 0.3s;
}

.header-container {
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 calc(var(--padding-default) * 1.125);
  height: calc(var(--padding-default) * 2);
}

.main-content {
  padding-top: calc(var(--padding-default) * 2);
}

.btn-voltar {
  position: absolute;
  left: 0;
  font-family: var(--font-nunito-sans);
  font-size: var(--size-xlarge-2);
  background: none;
  border: none;
  color: var(--color-secondary);
  cursor: pointer;
  padding: 0 calc(var(--padding-default) * 1.125);
}

.btn-voltar:hover {
  color: var(--color-primary);
}

.curso-detalhes {
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(var(--color-dark-rgb), 0.4);
  margin: calc(var(--padding-default) * 0.75);
  padding: calc(var(--padding-default) * 0.375);
  border-radius: 10px;
}

.curso-capa {
  position: relative;
  margin: 0 calc(var(--padding-default) * 0.75) calc(var(--padding-default) * 0.75) 0;
}

.capa-img {
  width: 300px;
  height: auto;
  border-radius: 10px;
}

.curso-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.curso-nome {
  font-family: var(--font-oxygen);
  font-size: var(--size-big-1);
  color: var(--color-primary);
}

.curso-descricao {
  font-family: var(--font-oxygen);
  font-size: var(--size-xlarge-2);
  margin-bottom: 10px;
  font-weight: bold;
}

.curso-estilo,
.curso-idioma,
.curso-duracao,
.curso-data-inicio {
  font-size: var(--size-large-1);
}

.italico {
  font-style: italic;
}

.curso-acoes {
  display: flex;
  margin-top: 20px;
  gap: 50px;
}

.curso-acao {
  text-align: center;
  cursor: pointer;
  color: var(--color-secondary);
  font-size: var(--size-large-1);
  transition: color 0.3s;
}

.curso-acao i {
  font-size: var(--size-xlarge-2);
  display: block;
}

.curso-acao span {
  display: block;
  margin-top: 5px;
}

.curso-acao:hover {
  color: var(--color-primary);
}

.curso-aulas {
  background-color: rgba(var(--color-dark-rgb), 0.4);
  margin: calc(var(--padding-default) * 0.75);
  padding: calc(var(--padding-default) * 0.375);
  border-radius: 10px;
}

.curso-aulas h2 {
  font-family: var(--font-oxygen);
  font-size: var(--size-xlarge-2);
  color: var(--color-secondary);
  margin-bottom: 15px;
}

.curso-aulas ul {
  list-style: none;
  padding: 0;
  font-size: var(--size-large-1);
}

.curso-aulas li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgba(var(--color-secondary-rgb), 0.1);
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid transparent;
}

.curso-aulas li:hover {
  border: 2px solid var(--color-primary);
}

.aula-detalhes {
  display: flex;
  align-items: center;
}

.aula-detalhes i {
  margin-right: 10px;
}

.aula-duracao {
  font-size: var(--size-large-1);
  color: var(--color-secondary);
  text-align: right;
}

/* Estilos do Player */
.tela-player {
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.player-header {
  max-width: 1600px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--color-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 calc(var(--padding-default) * 1.125);
  height: calc(var(--padding-default) * 2);
}

.btn-favoritar {
  position: absolute;
  right: calc(var(--padding-default) * 0.375);
  font-size: var(--size-xlarge-2);
  background: none;
  border: none;
  color: var(--color-secondary);
  cursor: pointer;
  padding: 0 calc(var(--padding-default) * 0.75);
}

.btn-favoritar:hover {
  color: var(--color-primary);
}

.favorito-ativo {
  color: var(--color-primary);
}

.media-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: calc(var(--padding-default) * 2) calc(var(--padding-default) * 0.5) 0;
}

.capa-img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(var(--color-dark-rgb), 0.2);
}

.exibir-letra {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.letras-container {
  width: 100%;
  height: 280px;
  overflow: hidden;
  position: relative;
}

.letras {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: var(--color-secondary);
  font-family: var(--font-nunito-sans);
  font-size: var(--size-large-3);
  line-height: 40px;
  transition: transform 0.3s ease-in-out;
}

.letras p {
  margin: 0;
  opacity: 0.5;
}

.letras p.ativa {
  opacity: 1;
  font-weight: bold;
}

.player-footer {
  max-width: 1600px;
  background-color: rgba(var(--color-dark-rgb), 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: calc(var(--padding-default) * 0.375) calc(var(--padding-default) * 1.125);
}

.info-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-line-1 {
  margin-bottom: 10px;
}

.aula-nome {
  font-family: var(--font-oxygen);
  font-size: var(--size-xlarge-2);
  font-weight: bold;
  color: var(--color-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.curso-nome {
  color: var(--color-secondary);
  font-size: var(--size-large-1);
  opacity: 0.5;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-ver-letra,
.btn-repeat {
  font-family: var(--font-nunito-sans);
  font-size: var(--size-large-1);
  background-color: rgba(var(--color-secondary-rgb), 0.2);
  border: none;
  padding: 8px 12px;
  border-radius: 15px;
  color: var(--color-secondary);
  cursor: pointer;
}

.btn-ver-letra:hover,
.btn-repeat:hover {
  background-color: rgba(var(--color-secondary-rgb), 0.5);
  color: var(--color-primary);
}

.repeat-active {
  color: var(--color-primary);
}

.audio-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controls {
  display: flex;
  align-items: center;
}

.controls button {
  background: none;
  border: none;
  color: var(--color-secondary);
  font-size: var(--size-large-1);
  margin: 0 20px;
  cursor: pointer;
}

.controls button:hover {
  color: var(--color-primary);
}

.controls button:nth-child(2) {
  background-color: rgba(var(--color-secondary-rgb), 0.2);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.controls button:nth-child(2):hover {
  background-color: rgba(var(--color-secondary-rgb), 0.4);
}

.progress-container {
  width: 100%;
  margin-top: 10px;
}

.progress-container input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  background-color: var(--color-secondary);
  border-radius: 5px;
  outline: none;
  transition: background 0.3s ease;
}

.time-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
}

.current-time,
.total-time {
  color: var(--color-primary);
  font-size: var(--size-small-3);
}

/* Responsividade */
@media (max-width: 768px) {
  .curso-acoes {
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .curso-nome {
    display: none;
  }
  .curso-detalhes {
    flex-direction: column;
    align-items: center;
  }
  .curso-capa {
    margin-right: 0;
  }
  .curso-info {
    text-align: center;
  }
  .capa-img {
    width: 100%;
    max-width: 260px;
  }
  .curso-acoes {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .curso-acao {
    margin-bottom: 10px;
    width: 80px;
  }
  .btn-ver-letra {
    display: flex;
    align-items: center;
  }
  .btn-ver-letra span {
    display: none;
  }
}
</style>
