<!-- src/views/TelaPagamento.vue -->
<template>
    <div class="tela-pagamento">
      <!-- Background -->
      <MainBackground />
  
      <!-- Cabeçalho da Tela -->
      <header class="header">
        <button class="btn-voltar" @click="voltar">
          <i class="fa-solid fa-arrow-left"></i> Voltar
        </button>
        <h1>Assinaturas</h1>
      </header>
  
      <!-- Seções da Tela de Pagamento -->
      <section class="pagamento-secao">
        <!-- Planos de Assinatura -->
        <SubscriptionPlan />
  
        <!-- Campo de Cupom de Desconto -->
        <CouponInput />
  
        <!-- Método de Pagamento -->
        <PaymentMethod />
  
        <!-- Histórico de Pagamento -->
        <PaymentHistory />
      </section>
    </div>
  </template>
  
  <script>
  // Importa os componentes de pagamento
  import MainBackground from "@/components/MainBackground.vue";
  import SubscriptionPlan from "@/components/SubscriptionPlan.vue";
  import CouponInput from "@/components/CouponInput.vue";
  import PaymentMethod from "@/components/PaymentMethod.vue";
  import PaymentHistory from "@/components/PaymentHistory.vue";
  
  export default {
    name: "TelaPagamento",
    components: {
      MainBackground,
      SubscriptionPlan,
      CouponInput,
      PaymentMethod,
      PaymentHistory
    },
    methods: {
      voltar() {
        this.$router.push({ name: "TelaConfiguracoes" });
      }
    }
  };
  </script>
  
  <style scoped>
  @import "@/assets/css/variables.css";
  
  /* Estilos da Tela de Pagamento */
  .tela-pagamento {
    position: relative;
    z-index: 1;
    color: var(--color-secondary);
  }
  
  .header {
    display: flex;
    align-items: center;
    padding: var(--padding-default);
    background-color: rgba(var(--color-dark-rgb), 0.8);
  }
  
  .btn-voltar {
    margin-right: auto;
    font-size: var(--size-large-1);
    color: var(--color-primary);
    background: none;
    border: none;
    cursor: pointer;
  }
  
  h1 {
    text-align: center;
    font-size: var(--size-big-1);
    color: var(--color-primary);
  }
  
  .pagamento-secao {
    padding: var(--padding-default);
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  </style>
  