// router.js

import { createRouter, createWebHistory } from 'vue-router';
import TelaInicio from '@/views/TelaInicio.vue';
import TelaCurso from '@/views/TelaCurso.vue';
import TelaPlayer from '@/views/TelaPlayer.vue';
import TelaQuiz from '@/views/TelaQuiz.vue';
import TelaTrilha from '@/views/TelaTrilha.vue';
import TelaLogin from '@/views/TelaLogin.vue';
import TelaCadastro from '@/views/TelaCadastro.vue';
import TelaConfirmacaoEmail from '@/views/TelaConfirmacaoEmail.vue';
import TelaRecuperacaoSenha from '@/views/TelaRecuperacaoSenha.vue';
import TelaConfiguracoes from '@/views/TelaConfiguracoes.vue';
import TelaPagamento from '@/views/TelaPagamento.vue'; // Importa a nova TelaPagamento
//import { auth } from './firebaseConfig'; // Importa o Firebase Auth

const routes = [
  {
    path: '/login',
    name: 'TelaLogin',
    component: TelaLogin
  },
  {
    path: '/cadastro',
    name: 'TelaCadastro',
    component: TelaCadastro
  },
  {
    path: '/confirmacao-email',
    name: 'TelaConfirmacaoEmail',
    component: TelaConfirmacaoEmail,
    // meta: { requiresAuth: true, emailNotVerified: true } // Acessível apenas se o email não estiver verificado
  },
  {
    path: '/recuperacao-senha',
    name: 'TelaRecuperacaoSenha',
    component: TelaRecuperacaoSenha
  },
  {
    path: '/inicio',
    name: 'TelaInicio',
    component: TelaInicio,
    // meta: { requiresAuth: true, emailVerified: true } // Protegido, requer login e email verificado
  },
  { 
    path: '/curso/:id',
    name: 'TelaCurso',
    component: TelaCurso,
    // meta: { requiresAuth: true, emailVerified: true } // Protegido, requer login e email verificado
  },
  { 
    path: '/trilha/:id',
    name: 'TelaTrilha',
    component: TelaTrilha,
    // meta: { requiresAuth: true, emailVerified: true } // Protegido, requer login e email verificado
  },
  { 
    path: '/player/:cursoId/:aulaId',
    name: 'TelaPlayer',
    component: TelaPlayer,
    // meta: { requiresAuth: true, emailVerified: true } // Protegido, requer login e email verificado
  },
  { 
    path: '/quiz/:id',
    name: 'TelaQuiz',
    component: TelaQuiz,
    // meta: { requiresAuth: true, emailVerified: true } // Protegido, requer login e email verificado
  },
  {
    path: '/configuracoes',
    name: 'TelaConfiguracoes',
    component: TelaConfiguracoes,
    // meta: { requiresAuth: true, emailVerified: true } // Protegido, requer login e email verificado
  },
  {
    path: '/pagamento',
    name: 'TelaPagamento',
    component: TelaPagamento,
    // meta: { requiresAuth: true, emailVerified: true } // Protegido, requer login e email verificado
  },
  {
    path: '/',
    redirect: '/inicio'  // Redireciona para TelaInicio como padrão
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/*
// Verificação de autenticação e confirmação de email antes de cada rota
router.beforeEach((to, from, next) => {
  const isAuthenticated = auth.currentUser; // Verifica se há um usuário autenticado
  const emailVerified = isAuthenticated?.emailVerified; // Verifica se o email do usuário está verificado

  if (to.meta.requiresAuth && !isAuthenticated) {
    // Se a rota requer autenticação e o usuário não está logado, redirecionar para login
    next({ name: 'TelaLogin' });
  } else if (isAuthenticated && !emailVerified && to.meta.emailVerified) {
    // Se o usuário não confirmou o email e a rota requer email verificado, redirecionar para TelaConfirmacaoEmail
    next({ name: 'TelaConfirmacaoEmail' });
  } else if (isAuthenticated && emailVerified && to.name === 'TelaConfirmacaoEmail') {
    // Se o usuário confirmou o email e tenta acessar a TelaConfirmacaoEmail, redirecionar para TelaInicio
    next({ name: 'TelaInicio' });
  } else if (isAuthenticated && (to.name === 'TelaLogin' || to.name === 'TelaCadastro')) {
    // Se o usuário está logado e tenta acessar login ou cadastro, redirecionar para TelaInicio
    next({ name: 'TelaInicio' });
  } else {
    next(); // Prosseguir para a rota desejada
  }
});
*/

export default router;
