<template>
  <div class="tela-login">
    <!-- Background -->
    <MainBackground />

    <!-- Conteúdo Principal -->
    <div class="main-content">
      <h1 class="login-titulo">Entrar</h1>

      <form @submit.prevent="fazerLogin" class="login-form">
        <!-- Campo de E-mail -->
        <div class="form-group" :class="{ 'has-error': emailErro }">
          <label for="email">E-mail</label>
          <input type="email" id="email" v-model="email" placeholder="Digite seu e-mail" required @paste.prevent autocomplete="off" />
          <span v-if="emailErro" class="error-message">{{ emailErro }}</span>
        </div>

        <!-- Campo de Senha -->
        <div class="form-group" :class="{ 'has-error': senhaErro }">
          <label for="senha">Senha</label>
          <input type="password" id="senha" v-model="senha" placeholder="Digite sua senha" required />
          <span v-if="senhaErro" class="error-message">{{ senhaErro }}</span>
        </div>

        <!-- Botão de Login -->
        <button type="submit" class="btn-login">Entrar</button>

        <!-- Link para Cadastro -->
        <p class="cadastro-link">
          Não tem uma conta?
          <a @click.prevent="irParaCadastro" href="#">Cadastre-se</a>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import MainBackground from "@/components/MainBackground.vue";

export default {
  name: "TelaLogin",
  components: {
    MainBackground,
  },
  data() {
    return {
      email: "",
      senha: "",
      emailErro: null,
      senhaErro: null,
    };
  },
  methods: {
    validarEmail() {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regex.test(this.email)) {
        this.emailErro = "Por favor, insira um e-mail válido.";
        return false;
      }
      this.emailErro = null;
      return true;
    },
    validarSenha() {
      if (this.senha.length < 6) {
        this.senhaErro = "A senha deve ter pelo menos 6 caracteres.";
        return false;
      }
      this.senhaErro = null;
      return true;
    },
    async fazerLogin() {
      const emailValido = this.validarEmail();
      const senhaValida = this.validarSenha();

      if (emailValido && senhaValida) {
        try {
          const auth = getAuth();
          const userCredential = await signInWithEmailAndPassword(auth, this.email, this.senha);
          const user = userCredential.user;

          if (user.emailVerified) {
            localStorage.setItem("userLoggedIn", true); // Salva status de login no localStorage
            alert("Login bem-sucedido!");
            this.$router.push({ name: "TelaInicio" });
          } else {
            alert("Por favor, verifique seu e-mail antes de acessar a plataforma.");
          }
        } catch (error) {
          if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
            this.senhaErro = "E-mail ou senha incorretos.";
          } else {
            console.error("Erro ao tentar fazer login:", error);
            alert("Erro ao conectar ao servidor.");
          }
        }
      } else {
        alert("Corrija os erros e tente novamente.");
      }
    },
    irParaCadastro() {
      // Redirecionar para a tela de cadastro
      this.$router.push({ name: "TelaCadastro" });
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/variables.css";

.tela-login {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: var(--color-secondary);
}

.main-content {
  position: relative;
  z-index: 2;
  background-color: rgba(var(--color-dark-rgb), 0.7);
  padding: var(--padding-default);
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.login-titulo {
  font-size: var(--size-big-2);
  color: var(--color-primary);
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-size: var(--size-large-1);
  margin-bottom: 5px;
  display: block;
  text-align: left;
  color: var(--color-secondary);
}

input {
  width: 100%;
  padding: 10px;
  font-size: var(--size-large-1);
  border: 2px solid var(--color-secondary);
  border-radius: 5px;
  background-color: rgba(var(--color-secondary-rgb), 0.1);
  color: var(--color-secondary);
}

input:focus {
  outline: none;
  border-color: var(--color-primary);
}

.btn-login {
  font-size: var(--size-large-1);
  padding: 10px;
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-login:hover {
  background-color: rgba(var(--color-primary-rgb), 0.8);
}

.cadastro-link {
  margin-top: 20px;
}

a {
  color: var(--color-highlight);
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

.has-error input {
  border-color: var(--color-primary);
}

.error-message {
  color: var(--color-primary);
  font-size: var(--size-small-3);
  text-align: left;
  margin-top: 5px;
}

/* Responsividade para Mobile */
@media (max-width: 480px) {
  .main-content {
    padding: var(--padding-default) 20px;
  }

  .login-titulo {
    font-size: var(--size-large-3);
  }

  input,
  .btn-login {
    font-size: var(--size-large-2);
  }
}
</style>
