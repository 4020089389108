<!-- src/components/PaymentHistory.vue -->
<template>
    <div class="payment-history">
      <h2>Histórico de Pagamentos</h2>
      <table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Plano</th>
            <th>Valor</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pagamento in historico" :key="pagamento.id">
            <td>{{ pagamento.data }}</td>
            <td>{{ pagamento.plano }}</td>
            <td>R$ {{ pagamento.valor.toFixed(2) }}</td>
            <td>{{ pagamento.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: "PaymentHistory",
    data() {
      return {
        historico: [
          { id: 1, data: "2024-01-01", plano: "Mensal", valor: 29.90, status: "Concluído" },
          { id: 2, data: "2024-02-01", plano: "Mensal", valor: 29.90, status: "Concluído" },
          // Adicione mais transações aqui
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .payment-history {
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
    border: 1px solid var(--color-primary);
    text-align: center;
  }
  
  th {
    background-color: var(--color-dark);
    color: var(--color-secondary);
  }
  
  td {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
  </style>
  