<template>
    <div class="tela-confirmacao-email">
      <!-- Background -->
      <MainBackground />
  
      <!-- Conteúdo Principal -->
      <div class="main-content">
        <h1 class="confirmacao-titulo">Confirmação de Email</h1>
        <p>Enviamos um link de verificação para o seu e-mail. Por favor, verifique sua caixa de entrada e confirme o email para acessar a plataforma.</p>
  
        <!-- Botão para reenviar link de verificação -->
        <button @click="reenviarEmailVerificacao" class="btn-reenviar" :disabled="reenviando">
          <span v-if="reenviando">Enviando...</span>
          <span v-else>Reenviar Email de Verificação</span>
        </button>
  
        <!-- Mensagem de Sucesso ou Erro -->
        <p v-if="mensagem" :class="{'success-message': sucesso, 'error-message': !sucesso}">{{ mensagem }}</p>
  
        <!-- Botão para verificar novamente -->
        <button @click="verificarConfirmacao" class="btn-verificar">
          Já confirmei meu e-mail
        </button>
  
        <!-- Botão para voltar ao login -->
        <p class="voltar-login">
          <a @click.prevent="voltarParaLogin" href="#">Voltar para o login</a>
        </p>
      </div>
    </div>
  </template>
  
  <script>
  import { getAuth, sendEmailVerification } from "firebase/auth";
  import MainBackground from "@/components/MainBackground.vue";
  
  export default {
    name: "TelaConfirmacaoEmail",
    components: {
      MainBackground,
    },
    data() {
      return {
        reenviando: false,
        mensagem: null,
        sucesso: false,
      };
    },
    methods: {
      async reenviarEmailVerificacao() {
        this.reenviando = true;
        const auth = getAuth();
        const user = auth.currentUser;
  
        if (user) {
          try {
            await sendEmailVerification(user);
            this.mensagem = "Email de verificação reenviado com sucesso!";
            this.sucesso = true;
          } catch (error) {
            console.error("Erro ao reenviar o email de verificação:", error);
            this.mensagem = "Erro ao reenviar o email de verificação. Tente novamente mais tarde.";
            this.sucesso = false;
          } finally {
            this.reenviando = false;
          }
        } else {
          this.mensagem = "Usuário não encontrado. Por favor, faça login novamente.";
          this.sucesso = false;
          this.reenviando = false;
        }
      },
      async verificarConfirmacao() {
        const auth = getAuth();
        const user = auth.currentUser;
  
        if (user) {
          await user.reload(); // Atualiza o status do usuário no Firebase
          if (user.emailVerified) {
            alert("Email confirmado com sucesso! Você será redirecionado para a plataforma.");
            this.$router.push({ name: "TelaInicio" });
          } else {
            this.mensagem = "O email ainda não foi confirmado. Verifique sua caixa de entrada.";
            this.sucesso = false;
          }
        } else {
          this.mensagem = "Usuário não encontrado. Por favor, faça login novamente.";
          this.sucesso = false;
        }
      },
      voltarParaLogin() {
        this.$router.push({ name: "TelaLogin" });
      },
    },
  };
  </script>
  
  <style scoped>
  @import "@/assets/css/variables.css";
  
  .tela-confirmacao-email {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: var(--color-secondary);
  }
  
  .main-content {
    position: relative;
    z-index: 2;
    background-color: rgba(var(--color-dark-rgb), 0.7);
    padding: var(--padding-default);
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .confirmacao-titulo {
    font-size: var(--size-big-2);
    color: var(--color-primary);
    margin-bottom: 20px;
  }
  
  p {
    font-size: var(--size-large-1);
    margin: 20px 0;
    color: var(--color-secondary);
  }
  
  .btn-reenviar,
  .btn-verificar {
    font-size: var(--size-large-1);
    padding: 10px;
    background-color: var(--color-primary);
    color: var(--color-secondary);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    width: 100%;
  }
  
  .btn-reenviar:disabled {
    background-color: rgba(var(--color-primary-rgb), 0.6);
    cursor: not-allowed;
  }
  
  .btn-reenviar:hover:enabled,
  .btn-verificar:hover {
    background-color: rgba(var(--color-primary-rgb), 0.8);
  }
  
  .voltar-login {
    margin-top: 20px;
  }
  
  a {
    color: var(--color-highlight);
    cursor: pointer;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .success-message {
    color: var(--color-highlight);
  }
  
  .error-message {
    color: var(--color-primary);
    font-size: var(--size-small-3);
    text-align: left;
    margin-top: 5px;
  }
  
  /* Responsividade para Mobile */
  @media (max-width: 480px) {
    .main-content {
      padding: var(--padding-default) 20px;
    }
  
    .confirmacao-titulo {
      font-size: var(--size-large-3);
    }
  
    .btn-reenviar,
    .btn-verificar {
      font-size: var(--size-large-2);
    }
  }
  </style>
  