<!-- src/components/configurations/EditProfileModal.vue -->
<template>
    <div class="edit-profile-modal">
      <h2>Editar {{ editField }}</h2>
      <input v-model="newValue" placeholder="Digite o novo valor" />
      <button @click="saveEdit">Salvar</button>
      <button @click="$emit('close')">Cancelar</button>
    </div>
  </template>
  
  <script>
  export default {
    name: "EditProfileModal",
    props: {
      editField: String
    },
    data() {
      return {
        newValue: ""
      };
    },
    methods: {
      saveEdit() {
        // Implementa a lógica para salvar a edição
        this.$emit("close");
      }
    }
  };
  </script>
  
  <style scoped>
  .edit-profile-modal {
    background-color: var(--color-dark);
    padding: 20px;
    border-radius: 5px;
    width: 300px;
  }
  button {
    color: var(--color-primary);
    cursor: pointer;
  }
  </style>
  